import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import UpNext from '@webMolecules/UpNext/UpNext';
import Featured from '@localTypes/Featured';
import { GatsbyImage } from 'gatsby-plugin-image';
import ModularHomeDesigns from '@webOrganisms/ModularHomeDesigns/ModularHomeDesigns';
import * as Route from '@routes';
import * as styles from './featuredBuildsTiles.module.scss';
import { tagCloudOptionsWithoutAll } from './tagConfig';
import CardFeaturedBuilds from '@webMolecules/Card/CardFeaturedBuilds';

export interface FeaturedBuildsProps {
  title: string;
  body: string;
  featured: Featured[];
}

const FeaturedBuildsTiles: React.FC<FeaturedBuildsProps> = ({
  title,
  body,
  featured,
}) => {
  const grouped = tagCloudOptionsWithoutAll.map(option => {
    const filtered = featured.filter(f => f.tags.includes(option.tag));
    return {
      ...option,
      featured: filtered,
    };
  });

  console.log(grouped);

  return (
    <>
      <Box>
        <Container>
          <Box className={styles.featuredBuildsHeading}>
            <Box className={styles.featuredBuildsHeadingColumn}>
              <h1>{title}</h1>
            </Box>
            <Box
              className={styles.featuredBuildsHeadingColumn}
              htmlContent={body}
            />
          </Box>
        </Container>

        <Container>
          <Box className={styles.featuredBuildsTiles}>
            {grouped.map(group => (
              <CardFeaturedBuilds
                key={group.tag}
                title={group.title}
                href={group.href}
                slides={group.featured.map(f => ({
                  slide: (
                    <Box className={styles.featuredBuildsCardSlide}>
                      <a href={`${Route.featuredBuilds(f.slug)}`}>
                        <GatsbyImage image={f.image} alt={f.title} />
                        <span className={styles.featuredBuildsCardSlideCaption}>
                          {f.title}
                        </span>
                      </a>
                    </Box>
                  ),
                }))}
              />
            ))}
          </Box>
        </Container>
      </Box>

      <ModularHomeDesigns />

      <UpNext
        links={[
          { label: 'Our process', href: Route.ourProcess() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </>
  );
};

export default FeaturedBuildsTiles;
