// extracted by mini-css-extract-plugin
export var active = "featuredBuildsTiles-module--active--HG7DG";
export var featuredBuildsCardSlide = "featuredBuildsTiles-module--featuredBuildsCardSlide--FReM1";
export var featuredBuildsCardSlideCaption = "featuredBuildsTiles-module--featuredBuildsCardSlideCaption--oYKYb";
export var featuredBuildsHeading = "featuredBuildsTiles-module--featuredBuildsHeading--IC0kv";
export var featuredBuildsHeadingColumn = "featuredBuildsTiles-module--featuredBuildsHeadingColumn--9Yup6";
export var featuredBuildsItem = "featuredBuildsTiles-module--featuredBuildsItem--z5goS";
export var featuredBuildsItemImageWrapper = "featuredBuildsTiles-module--featuredBuildsItemImageWrapper--i5Ib9";
export var featuredBuildsItemLink = "featuredBuildsTiles-module--featuredBuildsItemLink--tQrnD";
export var featuredBuildsItems = "featuredBuildsTiles-module--featuredBuildsItems--SFVb-";
export var featuredBuildsTags = "featuredBuildsTiles-module--featuredBuildsTags--OGzZ1";
export var featuredBuildsTiles = "featuredBuildsTiles-module--featuredBuildsTiles--ShdG1";