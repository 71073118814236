import React from 'react';
import Box from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Carousel, { CarouselSlide } from '@webOrganisms/Carousel/Carousel';
import Card from './Card';
import * as styles from './cardFeaturedBuilds.module.scss';

export type CardFeaturedBuildsProps = {
  href: string;
  slides: CarouselSlide[];
  title: string;
  className?: string;
};

const CardFeaturedBuilds: React.FC<CardFeaturedBuildsProps> = ({
  href,
  slides,
  title,
  className,
}) => {
  return (
    <Card
      className={`${styles.cardFeaturedBuilds} ${className || ''}`}
      // to={href}
    >
      <Box className={styles.cardFeaturedBuildsCarousel}>
        <Carousel type="card" slides={slides} />
      </Box>
      <Box className={styles.cardFeaturedBuildsBody}>
        <h3>{title}</h3>

        <Box className={styles.cardFeaturedBuildsCTA}>
          <Button
            href={href}
            className={styles.cardFeaturedBuildsCTAButton}
            icon="arrowRight"
          >
            See all {title}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default CardFeaturedBuilds;
